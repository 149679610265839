
import './App.css';
import Footer from './Components/Footer/Footer';
import Main from './Components/Main/Main';
import Powerby from './Components/Powerby/Powerby';
import Slider from './Components/Slider/Slider';
import Form from './Components/Footer/Form/Form';
import Home from './Components/Home/Home';



function App() {
  return (
    <div className="App">
      <Home/>
      <Main/>
      <Slider/>
      <Footer/>
      <Form/>
      <Powerby/>
    </div>
  );
}

export default App;
