import React from 'react'
import "./FooterM.scss"

function FooterM() {
    return (
        <div className="footerm">
            <div>
                <h2> UTILIZA SPORTBOX COMO PALANCA COMERCIAL</h2>
            </div>
        </div>
    )
}

export default FooterM