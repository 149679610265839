import React from 'react'
import "./Main.scss"
import sevilla from "../../Assets/Desktop/sevilla.png"
import granada from "../../Assets/Desktop/granada.png"
import bilbao from "../../Assets/Desktop/bilbao.png"
import madrid from "../../Assets/Desktop/madrid.png"
import atletico from "../../Assets/Desktop/atletico.png"
import barsa from "../../Assets/Desktop//barcelona.png"
import sociedad from "../../Assets/Desktop/sociedad.png"
import espana from "../../Assets/Desktop/RFEF-Logo_principal.png"
import betis from "../../Assets/Desktop/betis.png"
import valladolid from "../../Assets/Desktop/valladolid.png"
// import marcas from "../../Assets/Desktop/Group 135.svg"
// import marcasM from "../../Assets/Mobile/Marcas-mobile.svg"
import video from "../../Assets/Desktop/VIDEO-Home.mp4"
import Ventajas from '../Ventajas/Ventajas'
import marca1 from "../../Assets/Icons/Group-147.svg"
import marca2 from "../../Assets/Icons/Group-148.svg"
import marca3 from "../../Assets/Icons/Group-149.svg"
import marca4 from "../../Assets/Icons/Group-150.svg"
import marca5 from "../../Assets/Icons/Group-151.svg"
import marca6 from "../../Assets/Icons/Group-152.svg"
import marca7 from "../../Assets/Icons/Group-153.svg"
import marca8 from "../../Assets/Icons/Group-154.svg"
import marca9 from "../../Assets/Icons/Group-155.svg"
import marca10 from "../../Assets/Icons/Group 169.svg"
import marca11 from "../../Assets/Icons/Group-157.svg"


export default function Main() {
    return (
        <div className="main">
            <div>
                <h2 className='main-text'>Nuestros Partners</h2>
            </div>

            <div className="main-escudos">
                <img src={sevilla} alt="escudo-equipo" />
                <img src={granada} alt="escudo-equipo" />
                <img src={bilbao} alt="escudo-equipo" />
                <img src={espana} alt="escudo-equipo" />
                <img src={madrid} alt="escudo-equipo" />                
                <img src={atletico} alt="escudo-equipo" />
                <img src={barsa} alt="escudo-equipo" />
                <img src={sociedad} alt="escudo-equipo" />
                <img src={betis} alt="escudo-equipo" />
                <img src={valladolid} alt="escudo-equipo" />
            </div>


            <div className='main-escudos-mv'>
                
                    <img src={sevilla} alt="escudo-equipo" />
                    <img src={granada} alt="escudo-equipo" />
                    <img src={bilbao} alt="escudo-equipo" />
                    
                
                
                    <img src={espana} alt="escudo-equipo" />
                    <img src={madrid} alt="escudo-equipo" />
                    <img src={atletico} alt="escudo-equipo" />
                    
                
                
                    <img src={barsa} alt="escudo-equipo" />
                    <img src={sociedad} alt="escudo-equipo" />
                    <img src={betis} alt="escudo-equipo" />                    
                
                    <img src={valladolid} alt="escudo-equipo" />
            </div>

           
            <div className="main-experiencias">
                <h1><b>MÁS DE 150 EXPERIENCIAS EXCLUSIVAS</b></h1>
                <span>DE LOS CLUBES DE FÚTBOL MÁS RECONOCIDOS</span>
                <p>Marca la diferencia en tu sector con la mejor selección de incentivos para captación y fidelización</p>
            </div>
            <div className="main-video-container">
                <div className="main-video">
                    <video autoPlay playsInline loop muted className="videodesk"><source src={video} type="video/mp4" /></video>
                </div>
            </div>
            <div className="main-marcas">
                <p>EMPRESAS QUE CONFÍAN EN NOSOTROS</p>
                {/* <img className="main-marcas-desktop" src={marcas} alt="marcas" />
                <img className="main-marcas-mobile" src={marcasM} alt="marcasM" /> */}
                <div className="marcas-images">
                    <img src={marca1} alt="marcas" />
                    <img src={marca2} alt="marcas" />
                    <img src={marca3} alt="marcas" />
                    <img src={marca4} alt="marcas" />
                    <img src={marca5} alt="marcas" />
                    <img src={marca6} alt="marcas" />
                    <img src={marca7} alt="marcas" />
                    <img src={marca8} alt="marcas" />
                    <img src={marca9} alt="marcas" />
                    <img src={marca10} alt="marcas" />
                    <img src={marca11} alt="marcas" />
                </div>
            </div>

            <Ventajas />

        </div>
    )
}
