import React from 'react'
import "./Footer1.scss"
import deporte from "../../../Assets/Icons/aficion.svg"
import gastro from "../../../Assets/Icons/comer.svg"
import turismo from "../../../Assets/Icons/turismo.svg"
import ocio from "../../../Assets/Icons/entrada.svg"
import line from "../../../Assets/Desktop/Line 52.svg"
import ticket from "../../../Assets/Icons/ticket.svg"
import ball from "../../../Assets/Icons/ball.svg"
import plus from "../../../Assets/Icons/signnomas.svg"

export default function Footer1() {
    return (
        <div className="footer1">

            <div className="footer1-content">
                <div className="footer1-title">
                    <h1>Descubre nuestras cajas regalo Sportbox</h1>
                </div>

                <div className="footer1-iconos-verdes-container">
                    <div className="footer1-iconos-verdes">
                        <img src={ticket} alt="entradas" />
                        <img src={line} alt="line" />
                        <p>ENTRADAS PARTIDOS Y TOURS</p>
                    </div>
                    <div className="footer1-iconos-verdes2">
                        <img src={ball} alt="pelota" />
                        <img src={line} alt="line" />
                        <p>ENTRENAMIENTOS Y PARTIDOS EN CAMPOS OFICIALES</p>
                    </div>
                </div>

                <div className="footer1-plus">
                    <img src={plus} alt="deporte" />
                </div>

                <div className="footer1-iconos-blancos-container">
                    <div className="footer1-iconos-blancos">
                        <img src={deporte} alt="deporte" />
                        <p>DEPORTE</p>
                    </div>
                    <div className="footer1-iconos-blancos">
                        <img src={gastro} alt="gastro" />
                        <p>GASTRO</p>
                    </div>
                    <div className="footer1-iconos-blancos">
                        <img src={turismo} alt="turismo" />
                        <p>TURISMO</p>
                    </div>
                    <div className="footer1-iconos-blancos">
                        <img src={ocio} alt="ocio" />
                        <p>OCIO</p>
                    </div>
                </div>

            </div>


        </div>
    )
}
