import React, { useState } from 'react'
import "./Reviews.scss"
import line from "../../Assets/Icons/Line 56.svg"
import stars from "../../Assets/Icons/Group 163.svg"


export const Reviews = () => {
    const [down, setDown] = useState(0)

    return (
        <div className="reviews">
            <div className="reviews-container">
                <div className="tittle">
                    <h1>LO QUE DICEN DE NUESTRAS EXPERIENCIAS</h1>
                </div>
                <div className="general-box">
                    <div className="individual-box">
                        <h2>Rodrigo</h2>
                        <img src={stars} alt="stars" />
                        <img src={line} alt="linea" />
                        <p>La semana pasada mi sobrino usó la Sportbox que le di por su cumpleaños. La canjeó por un entrenamiento en el campo de La Selección. ¡Vino corriendo a contármelo! Una experiencia inolvidable.</p>
                    </div>
                    {
                        down === 0 ? null : down === 2 && <p>funciona pero 2</p>
                    }
                    <div onClick={() => setDown(2)} className="individual-box-center">
                        <h2>Nieves Ibañez</h2>
                        <img src={stars} alt="stars" />
                        <img src={line} alt="linea" />
                        <p>Le regalé una Sportbox a mi hijo y después de ver el catálogo estaba muy emocionado!! Fue con su amigo e hicieron el tour del Barça juntos. Les gustó mucho.</p>
                    </div>
                    {/* {number1 !== 9 ? <HostIcon onClick={() => setNumber1(9)} /> : number1 === 9 && <HostIcon onClick={() => setNumber1(20)} />} */}
                    {
                        down === 0 ? null : down === 1 && <p>funciona</p>
                    }
                    <div onClick={() => setDown(1)} className="individual-box">
                        <h2>Rodrigo</h2>
                        <img src={stars} alt="stars" />
                        <img src={line} alt="linea" />
                        <p>Una experiencia increíble. Emociona poder conocer a un ídolo deportivo en su faceta más personal. Compartir la forma de sentir el fútbol conociendo a futbolistas de primer nivel es irrepetible. Gracias por este Meet&Greet con la selección!.</p>
                    </div>
                </div>
            </div>
            <div className="button-container">
                <button
                onClick={() =>
                        window.open(
                        "https://bstadium.es"
                        )
                    }>REGALA SPORTBOX</button>
            </div>
        </div>
    )
}
