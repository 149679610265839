import React from 'react'
import Navbar from '../Navbar/Navbar'
/* import ContactUs from '../Formulario/ContactUs' */
import "./Home.scss"

export default function Home() {
    return (
        <div className="home">
            <Navbar />

            <div className='main-home-section'>
                <div className="title">
                    <h1>BE FUN. BE SOCIAL.</h1>
                    <h1 className="bstadium-text">BSTADIUM</h1>
                    <div className="bstadium-regala">
                        <h2><b>REGALAR</b> EXPERIENCIAS <b>DESDE</b> 12€</h2>
                    </div>
                </div>

                {/* <ContactUs />      */}           
            </div>

           {/*  <div className="experiencias">
                <h2>REGALAR <b>EXPERIENCIAS</b> DESDE</h2>
                <div className='experiencias-precio'>12€</div>                
            </div> */}

        </div>
    )
}
