import React, { useRef, useState } from 'react';
import "./Form.scss"
import emailjs from '@emailjs/browser';
import { motion } from "framer-motion"

export default function Form() {
    const form = useRef();
    const [received, setReceived] = useState(false);

    const sendEmail = (event) => {
        event.preventDefault();
        emailjs.sendForm('service_f44rno2', 'template_0l8mpcv', event.target, 'd0RpjhV6JfLsc5KLH')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        form.current.reset()
        console.log('sendEmail')
    };


    return (
        <div className="contacto2">
            <p>O DÉJANOS TUS DATOS, Y UN <b>ASESOR TE CONTACTARÁ</b></p>
            <form className="form-container" ref={form} onSubmit={sendEmail}>
                <input type="text" placeholder="Nombre" name="name"  required />
                <input type="email" placeholder="Email" name="email"  required />
                <input type="number" placeholder="Teléfono" name="phone" required />
                <input type="text" placeholder="Empresa" name="company" required />
                <input type="text" placeholder="Consulta" name="info" required />
                <input onClick={() => setReceived(true)} type="submit" className="btn-contact" value="Contáctanos" />
            </form>
            {
                received ? <motion.p
                    className="confirmation"
                    animate={{
                        scale: [0, 1]
                    }}
                    transition={{ ease: "easeOut", duration: 2 }}>Formulario Enviado <b>Correctamente</b></motion.p> : null
            }
        </div>
    )
}
