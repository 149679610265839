import React from 'react'
import Footer1 from './Footer1/Footer1'
import Footer2 from './Footer2/Footer2'
import FooterM from './FooterM/FooterM'

export default function Footer() {
    return (
        <div>
            <Footer1 />
            <FooterM />
            <Footer2 />
        </div>
    )
}
