import React from 'react'
import "./Footer2.scss"
import sobre from "../../../Assets/Icons/Union.svg"


export default function Footer2() {
    return (
        <div className="contacto">


            <div className="contacto-title">
                <h1>CONTACTA CON NOSOTROS PARA CONOCER MÁS DE NUESTROS PRODUCTOS Y SERVICIOS</h1>
            </div>

            <a className="mailto" href="mailto:sportbox@bstadium.es" target="_blank" rel="noreferrer">
                <div className="contacto-button">
                    <img src={sobre} alt="sobre" />
                    <p>SPORTBOX@BSTADIUM.ES</p>

                </div>
            </a>

        </div>

    )
}
