import React, { useState, useEffect, useRef } from 'react';
import './Slider.scss'
import { motion } from "framer-motion"
import image1 from "../../Assets/Desktop/1-Entradas-exclusivas-y-Fan-Zones.jpg";
import image2 from "../../Assets/Desktop/2Eventos-corporativos.jpg";
import image3 from "../../Assets/Desktop/item1.jpg";
import image4 from "../../Assets/Desktop/4Entrenamientos-o-actividades grupales.jpg";
import image5 from "../../Assets/Desktop/5Tours-interactivos-por estadios-y-planes-de-ocio.jpg";
import image6 from "../../Assets/Desktop/6Experiencias-interactivas1.png";
/* import image7 from "../../Assets/Desktop/7Tours-originales-alrededor-del-futbol.jpg"; */
import image8 from "../../Assets/Desktop/8Matchday-Experience.png";
import { Reviews } from '../Reviews/Reviews';


function Slider() {
    const [width, setWidth] = useState(0);
    const [/* down */, /* setDown */] = useState(1);

    const carousel = useRef();
    useEffect(() => {
        setWidth(carousel.current.scrollWidth - carousel.current.offsetWidth);
    }, []);

    return (

        <div className="carousel-container">
            <div className="carousel-text">
                <h1>PORQUE EL FÚTBOL ES MUCHO MÁS QUE FÚTBOL</h1>
                <p>Utiliza nuestras experiencias como palanca comercial</p>
            </div>
            <motion.div ref={carousel} className="carousel">
                <motion.div className="inner-carousel"
                    drag="x"
                    dragConstraints={{ right: 0, left: -width }}
                    style={{ x: 0 }} animate={{ x: -width }}
                    transition={{
                        repeatType: "reverse",
                        repeat: Infinity,
                        duration: 100
                    }}>
                    {/* <motion.div className="item"

                        whileTap={{ scale: 1.05 }}
                    >
                        <img onClick={() => setDown(2)} src={image1} alt="uno" />
                        {
                            down === 1 ? null : down === 2 && <motion.div animate={{
                                opacity: [0, 1]
                            }} transition={{ ease: "easeOut", duration: 1 }} className="dropdown">
                                <div className="tittle">
                                    <h2>TOURS INTERACTIVOS + PLANES DE OCIO</h2>
                                    <div className="number-promo">
                                        <p>45€</p>
                                    </div>
                                </div>
                                <div className="button-section">
                                    <p>Valida para canjear duarnte un año. Disponible en formato caja virtual</p>
                                    <button>COMPRAR</button>
                                </div>
                            </motion.div>
                        }

                    </motion.div> */}
                    <div className="item">
                        <img src={image3} alt="uno" />
                        <div className="dropdown">
                                <div className="tittle">
                                    <h2>ENTRENA CON LA SELECCIÓN ESPAÑOLA</h2>
                                    {/* <div className="number-promo">
                                        <p>45€</p>
                                    </div> */}
                                </div>
                                <div className="button-section">
                                    {/* <p>Valida para canjear duarnte un año. Disponible en formato caja virtual</p> */}                                    
                                    <button
                                        onClick={() =>
                                            window.open(
                                            "https://bstadium.es/salta-al-campo/entrena-como-la-seleccion-espanola-iniciacion/"
                                            )
                                        }
                                        className="btn-ticket">CONOCE MÁS</button>
                                </div>
                            
                        </div>
                    </div>
                        
                    <div className="item">
                        <img src={image5} alt="dos" />
                            <div className="dropdown">
                                <div className="tittle">
                                    <h2>Tour por el Estadio Santiago Bernabéu y ruta turística o gastronómica por Madrid</h2>
                                    {/* <div className="number-promo">
                                        <p>45€</p>
                                    </div> */}
                                </div>
                                <div className="button-section">
                                    {/* <p>Valida para canjear duarnte un año. Disponible en formato caja virtual</p> */}
                                    <button
                                        onClick={() =>
                                            window.open(
                                            "https://bstadium.es/real-madrid-cf/tour-por-el-estadio-santiago-bernabeu-y-ruta-turistica-madrid-y-el-futbol/"
                                            )
                                        }
                                        className="btn-ticket">CONOCE MÁS</button>
                                </div>
                            </div>                    
                    </div>
                    
                    <div className="item">
                        <img src={image1} alt="tres" />
                            <div className="dropdown">
                                <div className="tittle">
                                    <h2>Entradas exclusivas y Fan Zones</h2>
                                    {/* <div className="number-promo">
                                        <p>45€</p>
                                    </div> */}
                                </div>
                                <div className="button-section">
                                   {/*  <p>Valida para canjear duarnte un año. Disponible en formato caja virtual</p> */}
                                   <button
                                        onClick={() =>
                                            window.open(
                                            "https://bstadium.es/entradas-de-partidos/"
                                            )
                                        }
                                        className="btn-ticket">CONOCE MÁS</button>
                                </div>
                            </div>                        
                    </div>
                    <div className="item">
                        <img src={image8} alt="cinco" />
                        
                            <div className="dropdown">
                                <div className="tittle">
                                    <h2>Matchday Experience</h2>
                                   {/*  <div className="number-promo">
                                        <p>45€</p>
                                    </div> */}
                                </div>
                                <div className="button-section">
                                    {/* <p>Valida para canjear duarnte un año. Disponible en formato caja virtual</p> */}
                                    <button
                                        onClick={() =>
                                            window.open(
                                            "https://vimeo.com/677679114"
                                            )
                                        }
                                        className="btn-ticket">CONOCE MÁS</button>
                                </div>
                            </div>                        
                    </div>
                    <div className="item">
                        <img src={image6} alt="seis" />
                            <div className="dropdown">
                                <div className="tittle">
                                    <h2>Tour Interactivos por estadios + Planes de Ocio</h2>
                                   {/*  <div className="number-promo">
                                        <p>45€</p>
                                    </div> */}
                                </div>
                                <div className="button-section">
                                   {/*  <p>Valida para canjear duarnte un año. Disponible en formato caja virtual</p> */}
                                   <button
                                        onClick={() =>
                                            window.open(
                                            "https://bstadium.es/granada-cf/tour-por-el-nuevo-los-carmenes-estadio-de-granada-cf/"
                                            )
                                        }
                                        className="btn-ticket">CONOCE MÁS</button>
                                </div>
                            </div>                        
                    </div>
                    <div className="item">
                        <img src={image4} alt="seis" />
                            <div className="dropdown">
                                <div className="tittle">
                                    <h2>Entrenamientos y actividades grupales</h2>
                                    {/* <div className="number-promo">
                                        <p>45€</p>
                                    </div> */}
                                </div>
                                <div className="button-section">
                                    {/* <p>Valida para canjear duarnte un año. Disponible en formato caja virtual</p> */}
                                    <button
                                        onClick={() =>
                                            window.open(
                                            "https://vimeo.com/677735932"
                                            )
                                        }
                                        className="btn-ticket">CONOCE MÁS</button>
                                </div>
                            </div>
                    </div>
                    <div className="item">
                        <img src={image2} alt="seis" />
                            <div className="dropdown">
                                <div className="tittle">
                                    <h2>Eventos corporativos y experiencias digitales</h2>
                                   {/*  <div className="number-promo">
                                        <p>45€</p>
                                    </div> */}
                                </div>
                                <div className="button-section">
                                    {/* <p>Valida para canjear duarnte un año. Disponible en formato caja virtual</p> */}
                                    <button
                                        onClick={() =>
                                            window.open(
                                            "https://www.youtube.com/watch?v=0V0mg4OPHkE"
                                            )
                                        }
                                        className="btn-ticket">PREGÚNTANOS</button>
                                </div>
                            </div>
                    </div>
                    
                </motion.div>
            </motion.div>

            <Reviews />
        </div>
    )
}

export default Slider

// 