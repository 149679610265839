import React from 'react'
import "./Ventajas.scss"
import experiencias from "../../Assets/Desktop/ventajas-img.png"
import regalo from "../../Assets/Icons/ventaja1.svg"
import pack from "../../Assets/Icons/ventaja2.svg"
import descuento from "../../Assets/Icons/ventaja3.svg"
import line from "../../Assets/Desktop/Line 52.svg"


export default function Ventajas() {
    return (
        <div className="ventajas">

            <div className="ventajas-title">
                <h1>VENTAJAS DE SPORTBOX</h1>
            </div>

            <div className="ventajas-content">
                <div className="ventajas-content-img">
                    <img src={experiencias} alt="experiencias" />
                </div>

                <div className="ventajas-content-icons">
                    <div className="ventajas-content-icons-gift">
                        <img src={regalo} alt="regalo" />
                        <img className="icon-line" src={line} alt="line" />
                        <p>Más de 150 experiencias de prestigio y calidad a escoger</p>
                    </div>
                    <div className="ventajas-content-icons-pack">
                        <img src={pack} alt="pack" />
                        <img className="icon-line" src={line} alt="line" />
                        <p>Te asesoramos tu pack en base a tus objetivos y perfil de clientes</p>
                    </div>
                    <div className="ventajas-content-icons-descuentos">
                        <img src={descuento} alt="descuento" />
                        <img className="icon-line" src={line} alt="line" />
                        <p>Garantizamos los mejores descuentos para que tus clientes y equipo comercial vivan una experiencia inolvidable</p>
                    </div>
                </div>
            </div>

            {/* <div className="ventajas-catalogo">
                <p>Nuestros expertos te ayudarán a personalizar tu pack que sorprenderá a tu equipo, clientes y red comercial</p>
                <button>DESCUBRE NUESTRO CATÁLOGO</button>
            </div> */}

        </div>
    )
}
